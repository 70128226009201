<script setup lang="ts">
import { SearchIcon } from '@heroicons/vue/solid'
import ButtonG from '@/modules/shared/components/ButtonComponent.vue'
import useModal from '@/modules/shared/composable/useModal'

const { showModal } = useModal()

interface Emits {
  (e: 'clearSearch'):void;
}
const emits = defineEmits<Emits>()

defineProps({
  title: {
    type: String,
    required: true
  },
  text: {
    type: String,
    required: true
  },
  textButton: {
    type: String,
    required: true
  },
  modalName: {
    type: String,
    required: true
  }
})

</script>

<template>
  <!-- Busqueda no encontrada -->
  <tbody class="border !border-b">
    <tr>
      <td class="bg-white" colspan="5">
        <div class="p-10 flex flex-col justify-center items-center">
          <div class="p-3.5 bg-indigo-100 rounded-full">
            <SearchIcon class="w-6 h-6 text-indigo-500" />
          </div>
          <p class="text-gray-900 text-base leading-6 font-medium pt-2">{{ title }}</p>
          <p class="text-gray-500 text-sm leading-5 w-1/3 m-auto text-center pt-1" v-html="text"></p>
          <div class="p-6 flex justify-center">
            <ButtonG type-color="bg-gray" class="!w-29.75" text="Clear search" @click="emits('clearSearch')" />
            <slot name="button">
              <ButtonG
                @click="showModal({ name: modalName })"
                :text=textButton
                class="ml-3 !w-41 pl-[15px] pr-[17px]"
              />
            </slot>

          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<style scoped>

</style>
