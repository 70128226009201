import { ref } from 'vue'
import { defineStore } from 'pinia'
import type { IMailboxes } from '@/modules/settings/interfaces/Mailboxes'
import type { IBhs } from '@/modules/settings/interfaces/BusinessHours'

export const useMailboxesStore = defineStore('mailboxes', () => {
  const mailboxes = ref<IMailboxes[]>([{
    email: '',
    dataAdded: '',
    dataFrom: '',
    bhsStatus: '',
    bhs: {
      hover: false,
      timezone: '',
      businessHours: []
    },
    status: '',
    teams: '',
    name: ''
  }])

  return {
    // properties
    mailboxes,

    // Actions
    setMailboxes (newMailboxes: IMailboxes[]) {
      mailboxes.value = [...newMailboxes]
    },
    setMailboxBusinessHours (mailbox:string, newSettingsGeneral: IBhs) {
      const ms = mailboxes.value.find(({ email }) => email === mailbox)
      if (ms && ms.bhsStatus !== 'Ignore') {
        ms.bhs.timezone = newSettingsGeneral.timezone
        ms.bhs.businessHours = newSettingsGeneral.businessHours
      }
    },
    setMailboxBhsStatus (mailbox:string) {
      const ms = mailboxes.value.find(({ email }) => email === mailbox)
      if (ms) {
        ms.bhsStatus = 'Custom'
      }
    }
  }
})
