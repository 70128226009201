<script setup lang="ts">
import { computed, toRefs } from 'vue'
import { PlusIcon } from '@heroicons/vue/outline'

interface Emits {
  (e: 'on-click'): void;
}

const emits = defineEmits<Emits>()

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  inactive: {
    type: Boolean,
    default: false
  },
  typeColor: {
    type: String,
    required: false,
    default: 'blue'
  },
  layout: {
    type: String,
    required: false,
    default: ''
  }
})
const { text, inactive, typeColor, layout } = toRefs(props)

const Color = computed(() => {
  let buttonG
  let particularB

  switch (layout.value) {
    case 'ButtonRemove':
      buttonG = 'block px-4 py-2 font-normal text-sm'
      break
    case 'ButtonRemoveSelected':
      buttonG = 'inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-30'
      break
    default:
      buttonG = 'py-2.25 px-4 cursor-pointer text-sm font-medium rounded-md text-gray-700 focus:outline-none'
  }

  switch (typeColor.value) {
    case 'red':
      particularB = inactive.value ? '!cursor-not-allowed !bg-red-300 !text-white' : 'bg-red-600 hover:bg-red-700 text-white'
      break
    case 'bg-gray':
      particularB = inactive.value ? '!cursor-not-allowed text-gray-700' : 'bg-white border border-solid border-gray-300 !text-gray-800 hover:bg-gray-50'
      break
    case 'gray':
      particularB = inactive.value ? '!cursor-not-allowed text-gray-700' : 'bg-white text-gray-700'
      break
    case 'flat':
      particularB = inactive.value ? '!cursor-not-allowed' : 'text-indigo-500 border-0 !p-0 shadow-none cursor-pointer'
      break
    default:
      particularB = inactive.value ? '!cursor-not-allowed !bg-indigo-200 !text-white' : 'bg-indigo-500 text-white hover:bg-indigo-600'
  }

  return `${buttonG} ${particularB}`
})

const Icon = computed(() => ([
  'Add a mailbox',
  'Add a new user',
  'Add a new team',
  'Create group',
  'Create a new team',
  'Create a new alert',
  'Create my first alert'
].includes(text.value)))
</script>

<template>
  <button
    :class="Color" @click="() => { !inactive && emits('on-click'); }"
    class="w-full hover:bg-gray-100 flex justify-center items-center"
    type="button"
  >
    <PlusIcon v-if="Icon" class="text-white h-4 w-4 inline-block mr-2" aria-hidden="true" />
    {{ text }}
  </button>
</template>
