<script setup lang="ts">
import { useToast } from 'vue-toastification'

import PageTitleSettings from '@/modules/settings/components/PageTitleSettings.vue'
import AlertsTable from '@/modules/explore/components/AlertsTable.vue'
import useModal from '@/modules/shared/composable/useModal'
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import useAlerts from '@/modules/explore/composables/useAlerts'

const { showModal } = useModal()
const { alerts } = useAlerts()
const { userInfo } = useUserInfo()
const toast = useToast()

const createAlert = () => {
  if (userInfo.value.maxAlerts > alerts.value.length) {
    showModal({ name: 'addAlerts' })
  } else {
    toast.error('You\'ve reched your limit of alerts')
  }
}
</script>

<template>
  <PageTitleSettings
    button="Create a new alert"
    placeholder="Search by alert name"
    modal-name="addAlerts">
    <template v-slot:title>
      <div class="flex items-center mb-1">
        <p class="text-gray-800 text-3xl font-bold leading-9">Alerts</p>
        <div v-if="alerts.length && userInfo.maxAlerts" class="self-center text-left ml-2">
          <p class="inline-flex items-center rounded-2xl bg-indigo-100 text-xs px-2 p-1 m-0 font-medium text-indigo-600">
            {{ `${alerts.length} of ${userInfo.maxAlerts} used` }}
          </p>
        </div>
      </div>
      <p class="text-base text-gray-500 mb-8">Set up alerts for unreplied emails to prevent slow responses and missed emails</p>
    </template>
    <template v-slot:button>
      <button
        type="button"
        class="drop-shadow-base py-2 px-4 cursor-pointer border border-transparent bg-indigo-500 text-sm font-medium rounded-md text-white focus:outline-none hover:bg-indigo-600"
        @click="createAlert">
        Create a new alert
      </button>
    </template>
  </PageTitleSettings>

  <AlertsTable />
</template>

<style scoped></style>
