<script setup lang="ts">
import PageTitleSettings from '@/modules/settings/components/PageTitleSettings.vue'
import TeamsTable from '@/modules/settings/components/table/taps/TeamsTable.vue'
import useTable from '@/modules/settings/composables/useTable'
import MODALS from '@/modules/shared/utils/modals_enum'

useTable().resetTable('team')
</script>

<template>
  <PageTitleSettings
    title="Teams"
    description="Group your mailboxes for easier analysis"
    button="Create new team"
    placeholder="Search teams"
    :modal-name="MODALS.addTeams"
  />
  <TeamsTable />
</template>

<style scoped></style>
