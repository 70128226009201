<script setup lang="ts">
import MailboxesTable from '@/modules/settings/components/table/taps/MailboxesTable.vue'
import PageTitleSettings from '@/modules/settings/components/PageTitleSettings.vue'
import useMailboxes from '@/modules/settings/composables/useMailboxes'
import useTable from '@/modules/settings/composables/useTable'
import useUserInfo from '@/modules/shared/composable/useUserInfo'
import MODALS from '@/modules/shared/utils/modals_enum'

const { userInfo } = useUserInfo()
const { mailboxes } = useMailboxes()

useTable().resetTable('email')

const modalName = () => {
  if (mailboxes.value.length === userInfo.value.maxMailboxes) return MODALS.mailboxLimitReached
  return MODALS.addMailboxes
}
</script>

<template>
  <PageTitleSettings
    title="Mailboxes"
    description="Manage the email accounts you’re tracking email statistics for"
    button="Add mailboxes"
    :max="userInfo.maxMailboxes"
    :data="mailboxes.length"
    :modal-name="modalName()"
  />

  <MailboxesTable />
</template>

<style scoped></style>
